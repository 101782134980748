import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';

import InputWrapper from './style';

function Input(props) {
  const {
    onChange, className, type, value, name, defaultValue, onBlur,
  } = props;
  return (
    <InputWrapper>
      <TextField
        name={name}
        size="small"
        margin="normal"
        type={type}
        value={value}
        variant="outlined"
        className={className}
        onChange={onChange}
        defaultValue={defaultValue}
        onBlur={onBlur}
      />
    </InputWrapper>
  );
}
Input.propTypes = {
  handleChange: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  className: PropTypes.string,
};
export default Input;
