import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import SelectWrapper from './style'

const  Select = (props) => {
  const { sx, data, handleChange, showLabel=false, label, value='', className } = props;
  return (
    <SelectWrapper>
      <form noValidate autoComplete="off">
        <TextField
          size="small"
          select
          label={showLabel? label: ''}
          value={value}
          onChange={handleChange}
          InputLabelProps={{ shrink: false }}
          margin="normal"
          variant="outlined"
          className={className}
          sx={sx}
        >
          {data?.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </form >
    </SelectWrapper>
  );
}
Select.propTypes = {
  handleChange: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  className: PropTypes.string
};

export default Select;
