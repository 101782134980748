import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosBaseUrl } from '../../config/axios-configuration'

const axios = axiosBaseUrl()

export const AdvertisingSummary = createAsyncThunk(
    '/dashboard/advertising-summary',
    async (params, { rejectWithValue }) => {
        try {
            // console.log({
            //     params,
            // })
            const response = await axios.get('/dashboard/advertising-summary', {
                params,
            })
            const responsedData = response.data
            return responsedData
        } catch (err) {
            if (err.response && err.response.data) {
                return rejectWithValue({
                    errorMessage: err.response.data.error,
                    status: err.response.status,
                })
            }
            return rejectWithValue({
                errorMessage: 'Network Error',
            })
        }
    }
)

export const AdvertisingGraphSummary = createAsyncThunk(
    '/dashboard/adverstisin-grpah',
    async (params, { rejectWithValue }) => {
        try {
            // console.log({
            //     params,
            // })
            const response = await axios.get('/dashboard/adverstisin-grpah', {
                params,
            })
            const responsedData = response.data
            return responsedData
        } catch (err) {
            if (err.response && err.response.data) {
                return rejectWithValue({
                    errorMessage: err.response.data.error,
                    status: err.response.status,
                })
            }
            return rejectWithValue({
                errorMessage: 'Network Error',
            })
        }
    }
)

export const SpAPISummary = createAsyncThunk(
    '/dashboard/sp-api-summary',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axios.get('/dashboard/sp-api-summary', {
                params,
            })
            const responsedData = response.data
            return responsedData
        } catch (err) {
            if (err.response && err.response.data) {
                return rejectWithValue({
                    errorMessage: err.response.data.error,
                    status: err.response.status,
                })
            }
            return rejectWithValue({
                errorMessage: 'Network Error',
            })
        }
    }
)

export const SpAPIGraphSummary = createAsyncThunk(
    '/dashboard/sp-api-graph',
    async (params, { rejectWithValue }) => {
        try {
            // console.log({
            //     params,
            // })
            const response = await axios.get('/dashboard/sp-api-graph', {
                params,
            })
            const responsedData = response.data
            return responsedData
        } catch (err) {
            if (err.response && err.response.data) {
                return rejectWithValue({
                    errorMessage: err.response.data.error,
                    status: err.response.status,
                })
            }
            return rejectWithValue({
                errorMessage: 'Network Error',
            })
        }
    }
)

const dashboard = createSlice({
    name: 'dashboard',
    initialState: {
        isLoading: false,
        success: false,
        errorMessage: '',
        total: 0,
        spApiLoading: true,
        advertisingSummary: [],
        spApiSummary: [],
        advGraphSummary: [],
        spApiGraphSummary: [],
    },
    extraReducers: {
        [AdvertisingSummary.pending]: (state, action) => ({
            ...state,
            products: null,
            isLoading: true,
        }),
        [AdvertisingSummary.fulfilled]: (state, action) => ({
            ...state,
            advertisingSummary: action.payload.advertisingDashboardSummary,
            isLoading: false,
            success: true,
        }),
        [AdvertisingSummary.rejected]: (state, action) => ({
            ...state,
            isLoading: false,
            errorMessage: action.payload.errorMessage,
        }),
        [AdvertisingGraphSummary.pending]: (state, action) => ({
            ...state,
            products: null,
            isLoading: true,
        }),
        [AdvertisingGraphSummary.fulfilled]: (state, action) => ({
            ...state,
            advGraphSummary: action.payload.graphSummary,
            isLoading: false,
            success: true,
        }),
        [AdvertisingGraphSummary.rejected]: (state, action) => ({
            ...state,
            isLoading: false,
            errorMessage: action.payload.errorMessage,
        }),
        [SpAPISummary.pending]: (state, action) => ({
            ...state,
            products: null,
            isLoading: true,
        }),
        [SpAPISummary.fulfilled]: (state, action) => ({
            ...state,
            spApiSummary: action.payload.spapiDashboardSummary,
            isLoading: false,
            success: true,
        }),
        [SpAPISummary.rejected]: (state, action) => ({
            ...state,
            isLoading: false,
            errorMessage: action.payload.errorMessage,
        }),
        [SpAPIGraphSummary.pending]: (state, action) => ({
            ...state,
            products: null,
            isLoading: true,
        }),
        [SpAPIGraphSummary.fulfilled]: (state, action) => ({
            ...state,
            spApiGraphSummary: action.payload.spapiGraphSummary,
            isLoading: false,
            success: true,
        }),
        [SpAPIGraphSummary.rejected]: (state, action) => ({
            ...state,
            isLoading: false,
            errorMessage: action.payload.errorMessage,
        }),
    },
})

const { reducer } = dashboard

export default reducer
