import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import PageNotFound from '../pages/other/page-not-found'
import PrivateRoute from './private-route'
import PublicRoute from './public-route'
import DashboardLayout from '../layout'
import Dashboard from '../pages/dashboard'
import Stores from '../pages/stores'
import Listing from '../pages/listing'
import AsinWiseSale from '../pages/sale'

import { setAuthToken } from '../config/axios-configuration'

const Index = () => {
    const { token } = useSelector((state) => state.auth)
    setAuthToken(token)

    return (
        <Switch>
            <Route exact path='/'>
                <Redirect to='/dashboard' />
            </Route>
            <PrivateRoute
                path='/dashboard'
                component={Dashboard}
                withLayout={DashboardLayout}
            />
            <PrivateRoute
                path='/listing'
                component={Listing}
                withLayout={DashboardLayout}
            />
            <PrivateRoute
                path='/stores'
                component={Stores}
                withLayout={DashboardLayout}
            />
            <PrivateRoute
                path='/asin-wise-sale'
                component={AsinWiseSale}
                withLayout={DashboardLayout}
            />
            <Route path='/not-found' component={PageNotFound} />
            <Redirect from='*' to='/not-found' />
        </Switch>
    )
}

export default Index
