import Styled from 'styled-components';

const DashboardWrapper = Styled.div`
  .cases-title{
    h6{
     color: #2B2B2B;
    font-size: 0.75rem;
    font-family: Montserrat,sans-serif;
    margin:0
    }
    span{
        color: ${({ theme }) => theme['chicago-color']};
    }
    border-bottom:1px dashed #ccc
  }
`;

export default DashboardWrapper;
