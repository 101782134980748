import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PrivateRoute from './private-route';
import PublicRoute from './public-route';

import DashboardLayout from '../layout';
import SignIn from '../pages/authentication/sign-in';
import SignUp from '../pages/authentication/sign-up';
import ForgetPassword from '../pages/authentication/forget-password';
import PageNotFound from '../pages/other/page-not-found';
import ConnectMarketPlaces from '../pages/stores/connect-marketplaces';

import { setAuthToken } from '../config/axios-configuration';

const AuthRoute = () => {
  const { token } = useSelector(state => state.auth);
  setAuthToken(token);

  return (
    <Switch>
      <PublicRoute path='/auth/sign-in' component={SignIn} />
      <PublicRoute path='/auth/sign-up' component={SignUp} />
      <PublicRoute path='/auth/forget-password' component={ForgetPassword} />
      <PrivateRoute path='/auth/advertising' component={ConnectMarketPlaces} withLayout={DashboardLayout} />
      <Route path='/not-found' component={PageNotFound} />
      <Redirect from='*' to='/not-found' />
    </Switch>
  );
}

export default AuthRoute;
