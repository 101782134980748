import moment from 'moment';
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import DateRangeIcon from '@mui/icons-material/DateRange';

export default function BasicMenu(props) {
  const { handleDateChange } = props; 
  const [state, setState] = useState([
    {
      startDate: new Date(2021, 12, 1),
      endDate: new Date(),
      key: "selection"
    }
  ]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    console.log('i am called');
    setAnchorEl(null);
  };

  const handleChange = (ranges) => {
    console.log('range is ', ranges);
    setState([ranges.selection]);
  }

  const submit = () => {
    handleDateChange({
      startDate: state[0].startDate,
      endDate: state[0].endDate
    })
    handleClose();
  }

  useEffect(() => {
    console.log('date changed', state);
  },[state])

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        style={{height: "42px"}}
        variant="outlined"
        onClick={handleClick}
      >
        <DateRangeIcon />
        {moment(state[0].startDate).format('DD/MM/YYYY')}-{
              moment(state[0].endDate).format('DD/MM/YYYY')
            }
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transitionDuration={0}
      >
        <MenuItem disableRipple>
          <DateRangePicker
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            onChange={handleChange}
            months={2}
            minDate={new Date(2021, 12, 1)}
            maxDate={new Date()}
            ranges={state}
            direction="horizontal"
            staticRanges={[
              {
                label: '3 days',
                range: () => ({
                  endDate: moment().subtract(2, 'days').endOf('day').toDate(),
                  startDate: moment().subtract(4, 'days').startOf('day').toDate(),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    moment(range.startDate).isSame(definedRange.startDate, 'day') &&
                    moment(range.endDate).isSame(definedRange.endDate, 'day')
                  );
                },
              },
              {
                label: 'A week',
                range: () => ({
                  emdDate: moment().subtract(2, 'days').endOf('day').toDate(),
                  startDate: moment().subtract(8, 'days').startOf('day').toDate(),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    moment(range.startDate).isSame(definedRange.startDate, 'day') &&
                    moment(range.endDate).isSame(definedRange.endDate, 'day')
                  );
                },
              },
            
              {
                label: 'A month',
                range: () => ({
                  endDate: moment().subtract(2, 'days').endOf('day').toDate(),
                  startDate: moment().subtract(32, 'days').startOf('day').toDate(),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    moment(range.startDate).isSame(definedRange.startDate, 'day') &&
                    moment(range.endDate).isSame(definedRange.endDate, 'day')
                  );
                },
              }
            ]}
          />
        </MenuItem>
        <MenuItem>
          <div style={{width: '100%'}}>
            <Button style={{float: 'right', fontSize: '13px'}}  variant="outlined" onClick={() => setAnchorEl(null)}>Cancel</Button>
            <Button style={{float: 'right', fontSize: '13px', marginRight: '10px'}}  variant="contained" onClick={submit}>Apply</Button>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}
