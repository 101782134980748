import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import LabelInput from '../../components/input/label-input';
import Button from '../../components/button';
import Typography from '@mui/material/Typography';

import AuthWrapper from './style';

import { SignUp, SetState } from '../../redux/slices/auth-slice';

const styles = {
    textDanger: {
      color: "#ff0000",
    },
    redirect: { 
      color: '#7373eb',
      textDecoration: 'underline', 
      fontSize: '14px',
      cursor: 'pointer'
    },
    font: {
      fontSize: '14px'
    }
  };

function Index() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('admin@amzpro.com');
  const [password, setPassword] = useState('Admin1');

  const { err, loading, success } = useSelector((state) => state.auth);

  const emailValidation = (email) => {
    const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regExp.test(email);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  
    if (!email || !password) {
      dispatch(SetState({ field: 'err', value: 'Email or Password is required.' }));
      return;
    } else if (!emailValidation(email)) {
      dispatch(SetState({ field: 'err', value: 'Please Enter a valid email address' }));
    }
    else {
      dispatch(SignUp({ email, password }));
    }
  };
  
  const signIn = () => {
    history.push('/auth/sign-in');
  }

  useEffect(() => {
    dispatch(SetState({ field: 'err', value: '' }));
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Grid item lg={4} md={5} sm={8} xs={12}>
        <AuthWrapper>
          <Box textAlign="center">
          </Box>
          <form
            noValidate
            autoComplete="off"
            onSubmit={(e) => handleFormSubmit(e)}
          >
            <LabelInput label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <LabelInput label="Password" value={password} type={'password'} onChange={(e) => setPassword(e.target.value)} />
            {err ? <small style={styles.textDanger}>{err}</small> : ""}
            {!loading 
              ? <Button text="SignUp" className="contained w-100" 
                type={'submit'}></Button> 
              : <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
            }
            <Box mt={5} textAlign="center" style={styles.font}>
                Have a account?
              <Typography style={styles.redirect} onClick={signIn}>
                Signin
              </Typography>
            </Box>
          </form>
        </AuthWrapper>
      </Grid>
    </Grid>
  );
}

export default Index;
