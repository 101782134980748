import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import LabelInput from '../../components/input/label-input';
import Button from '../../components/button';
import AuthWrapper from './style';

import { ForgotPassword, SetState } from '../../redux/slices/auth-slice';

const style = {
  textSuccess: {
    color: "#0B6623",
  },
  textDanger: {
    color: "#ff0000",
  }
};

function Index() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const { err, message } = useSelector((state) => state.auth);

  const emailValidation = (email) => {
    const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regExp.test(email);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      dispatch(SetState({ field: 'err', value: 'Email is required.' }));
      return;
    } else if (!emailValidation(email)) {
      dispatch(SetState({ field: 'err', value: 'Please Enter a valid email address' }));
    } 
    else {
      dispatch(ForgotPassword({ email }));
      dispatch(SetState({ field: 'err', value: '' }));
    }
  };

  useEffect(() => {
    dispatch(SetState({ field: 'err', value: '' }));
    dispatch(SetState({ field: 'message', value: '' }));
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Grid item lg={4} md={5} sm={8} xs={12}>
        <AuthWrapper>
          <form
            noValidate
            autoComplete="off"
            onSubmit={(e) => handleFormSubmit(e)}
          >
            <LabelInput label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            {!err ? <small style={style.textSuccess}>{message}</small> : <small style={style.textDanger}>{err}</small> }
            <Button
              text="Send Email"
              className="contained w-100"
              type="submit"
            />
          </form>
        </AuthWrapper>
      </Grid>
    </Grid>
  );
}

export default Index;
