import axios from 'axios'

const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
    }
}

const axiosBaseUrl = () => {
    axios.defaults.baseURL =
        process.env.NODE_ENV === 'development'
           // ? 'http://localhost:9010/'
            ? 'https://dev.api.gembeacon.com/'
            : 'https://api.gembeacon.com/'
            //: 'https://apis.gembeacon.com/'

    return axios
}

export { setAuthToken, axiosBaseUrl }
