import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { Stack, Box } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';

import Loading from '../../loading';
import Select from '../../select';
import TableWrapper from './style';

const SimpleTable = (props) => {
  const { rows, columns, data, totalRows, pageNumber, pageLimit: limit, loading, className } = props;
  const changePageNumber = (e, value) => {
    props.onChange(value, limit);
  }

  const handleLimitChange = (e) => {
    props.onChange(1, e.target.value);
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableWrapper>
        <TableContainer className={className}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, whiteSpace: 'nowrap' }}
                  >
                    {column?.id === "product" || column?.id === 'marketPlace' || column?.id === "unitsSold" || column?.id === "orderSales" || column?.id === "saleCostPrice" || column?.id === "unitsRefunded" || column?.id === "refundSales" || column?.id === "refundCostPrice" || column?.id === "productSales" || column?.id === "shippingCredits" || column?.id === "giftWrapCredits" || column?.id === "promotionalRebates" || column?.id === "sellingFees" || column?.id === "fbaFees" || column?.id === "otherTransactionFees" || column?.id === "other" ? <b>{column.label}</b> : <b> {column.label}</b>}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!loading ?
              <TableBody>
                {rows?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
              :
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Loading />
                  </TableCell>
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
        <Stack direction="row" justifyContent="space-between" sx={{ mx: 2, my: 1 }} alignItems="center" className="pagination">
          <Box display="flex" columnGap={5}>
            <label>Total:<span className="ml-1"><b>{totalRows}</b></span></label>
          </Box>
          <Box display="flex" columnGap={2} alignItems="center">
            <Box display="flex" alignItems="center" columnGap={2}>
              <label>Showing</label>
              <Select data={data} sx={{ width: 80 }} handleChange={handleLimitChange} value={limit} selected />
            </Box>
            <Pagination count={Math.ceil(totalRows / limit)} page={pageNumber} color="primary" onChange={changePageNumber} />
          </Box>
        </Stack>
      </TableWrapper>
    </Paper>
  );
}

export default SimpleTable;
