import React, { useEffect, useState } from 'react';
import { 
  Typography, 
  Grid, 
  Box, 
  Button, 
  CircularProgress, 
  Checkbox, 
  FormControlLabel  
} from '@mui/material'


import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';


const ConnectMarketPlace = () => {
  const dispatch = useDispatch();
  const { 
    storesData, 
    storeId,
    storeName,
    activeMarketplaces, 
    loading, 
    activeMarketplacesSuccess, 
    marketplaceEditSuccess,
    getStoreFlag,
    err,
    success,
    editSuccess
  } = useSelector(state => state.stores) || {};
  const history = useHistory();
  const [state, setState] = useState([]);
  const [store, setStore] = useState({});

  const handleChange = (marketPlaceId) => {
    const id = state.find(a => a === marketPlaceId);
    if (id) {
      const filtered = state.filter(a => a !== marketPlaceId);
      setState(filtered);
    }
    else {
      const idsArray = [...state, marketPlaceId];
      setState(idsArray);
    }
  };


  // useEffect(() => {
  //   if (storesData.length > 0 && getStoreFlag && storeId) {
  //     dispatch(GetActiveMarketplaces({ storeId }));
  //     let selectedStore = storesData.find(store => store._id === storeId);
  //     setStore(selectedStore);
  //     let marketplaces = selectedStore?.marketplaces?.map(marketplace => marketplace.marketplaceId);
  //     setState(marketplaces);
  //   }
  // }, [storesData])

  // useEffect(() => {
  //   if (err && !success) {
  //     history.push('/setting/store');
  //   }
  // }, [err, success]);

  // useEffect(() => {
  //   if (editSuccess) {
  //     dispatch(GetStores({
  //       searchKeyWord: '',
  //       skip: 0,
  //       limit: 10
  //     }));
  //   }
  // }, [editSuccess]);
  // const handleSubmit = () => {
  //   dispatch(EditStoreData({ storeId, name: storeName, marketplaces: state }));
  // };
  
  // useEffect(() => {
  //   if (marketplaceEditSuccess) {
  //     dispatch(setMarketplaceEdit({ marketplaceEditSuccess: false }));
  //     history.push('/setting/store');
  //   }
  // }, [marketplaceEditSuccess])

  return (
    <div>
      {/* {!loading && success && */}
      { true &&
      <>
      <Box p={1} display="flex" flexDirection="column" alignItems="center">
        <Grid md={8} item>
          <Typography className="upper-heading">Select the Amazon Marketplace you would like to add</Typography>
          <Typography className="paragraph">Select any marketplaces that you sell in and would like to add to your account. You will be able to add additional regions/marketplaces at anytime.</Typography>
          <Box display="flex" flexDirection="column" sx={{paddingLeft: '22.5%'}} mb={9} mt={5}>
            {activeMarketplacesSuccess && store?.region && Regions[store.region] && activeMarketplaces && Regions[store.region].filter(marketPlace => activeMarketplaces.some(active => marketPlace.marketPlaceId === active))?.map((market, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox                               
                    checked={state.findIndex(a => a === market.marketPlaceId) > -1 ? true : false}
                    onChange={() => handleChange(market.marketPlaceId)}
                    name={market.name}
                    color="primary"
                  />
                }
                label={
                  <Box display="flex" alignItems="center" gridColumnGap={5} className="checkbox-label">
                    <img src={market.image} height="25" width="25" />{market.domain}
                  </Box>
                }
              />
            ))}
          </Box>
          <Box sx={{marginLeft: '22.5%'}} className="buttons" display="flex" gridColumnGap={15}>
            <Button variant="contained" className="contained-button" onClick={() => handleSubmit()}>
              Save
            </Button>
            <Button sx={{marginLeft: '3%'}} variant="contained" className="contained-button" onClick={() => dispatch(SetState({ field: 'editStore', value: false }))}>
              Cancel
            </Button>
          </Box>
        </Grid>
      </Box> </>}
      {loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '20%', paddingBottom: '25%' }}>
        <div style={{ display: 'flex' }}>
          <CircularProgress thickness={1} size={80} />
        </div>
      </div>}
    </div>
  )
}

export default ConnectMarketPlace
