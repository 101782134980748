import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { axiosBaseUrl } from '../../config/axios-configuration'

const axios = axiosBaseUrl()

export const GetAdvertisingAPIToken = createAsyncThunk(
    'stores/get-advertising-refresh-token',
    async (data, { rejectWithValue }) => {
        try {
            //   console.log({
            //     data
            //   });
            const response = await axios.post(
                '/stores/refreshTokenLWAForAdvertising',
                data
            )
            return response.data
        } catch (err) {
            if (err.response && err.response.data) {
                return rejectWithValue({
                    err: err.response.data.error,
                    status: err.response.status,
                })
            }
            return rejectWithValue({
                err: 'Network Error',
            })
        }
    }
)

export const CreateStore = createAsyncThunk(
    'store/create-store',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post('/stores/create-store', data)
            return response.data
        } catch (err) {
            if (err.response && err.response.data) {
                return rejectWithValue({
                    err:
                        err.response.data.message ||
                        err.response.data.errorMessage,
                    status: err.response.status,
                })
            }
            return rejectWithValue({
                err: 'Network Error',
            })
        }
    }
)

export const GetStores = createAsyncThunk(
    'user/get-stores',
    async (data, { rejectWithValue }) => {
        try {
            let filter = {}
            if (data.searchKeyword) {
                filter = {
                    keyword: {
                        value: data.searchKeyword,
                    },
                }
            }
            const response = await axios.get('/stores/get-stores', {
                params: {
                    skip: data.skip || 0,
                    sort: data.sort,
                    limit: data.limit,
                    filters: filter,
                },
            })
            return response.data
        } catch (err) {
            if (err.response && err.response.data) {
                return rejectWithValue({
                    err: err.response.data,
                    status: err.response.status,
                })
            }
            return rejectWithValue({
                err: 'Network Error',
            })
        }
    }
)

export const SearchStores = createAsyncThunk(
    'user/get-stores',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.get('/stores/search-stores', {
                params: {
                    skip: data.skip || 0,
                    limit: data.limit || 10,
                    searchString: data.searchString,
                },
            })
            return response.data
        } catch (err) {
            if (err.response && err.response.data) {
                return rejectWithValue({
                    err: err.response.data,
                    status: err.response.status,
                })
            }
            return rejectWithValue({
                err: 'Network Error',
            })
        }
    }
)

export const GetActiveProfiles = createAsyncThunk(
    'stores/get-active-profiles',
    async (data, { rejectWithValue, getState }) => {
        try {
            // const state = getState();
            // const { storeId } = state.store;
            const response = await axios.get('/stores/get-active-profiles', {
                params: {
                    storeId: data.storeId,
                },
            })
            return response.data
        } catch (err) {
            if (err.response && err.response.data) {
                return rejectWithValue({
                    err: err.response.data.error,
                    status: err.response.status,
                })
            }
            return rejectWithValue({
                err: 'Network Error',
            })
        }
    }
)

export const EditStoreData = createAsyncThunk(
    'stores/edit-store-data',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.put('/stores/edit-store-data', data)
            return response.data
        } catch (err) {
            if (err.response && err.response.data) {
                return rejectWithValue({
                    err: err.response.message || err.message,
                    status: err.response.status,
                })
            }
            return rejectWithValue({
                err: 'Network Error',
            })
        }
    }
)

export const DeleteStore = createAsyncThunk(
    'stores/remove-store',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.delete('/stores/remove-store', {
                data: {
                    storeId: data.storeId,
                },
            })
            return response.data
        } catch (err) {
            if (err.response && err.response.data) {
                return rejectWithValue({
                    err: err.response.message || err.message,
                    status: err.response.status,
                })
            }
            return rejectWithValue({
                err: 'Network Error',
            })
        }
    }
)

const stores = createSlice({
    name: 'stores',
    initialState: {
        err: '',
        success: false,
        loading: false,
        total: 0,
        storeName: '',
        spApiRegion: '',
        storeCreated: false,
        storeSuccess: false,
        storeId: '',
        marketplaceEditSuccess: false,
        message: null,
        stores: [],
        deleteSuccess: false,
    },
    reducers: {
        ClearAll(state, action) {
            return {
                ...state,
                data: [],
            }
        },
        setField(state, { payload: { field, value } }) {
            state[field] = value
        },
        SetState(state, { payload: { field, value } }) {
            state[field] = value
        },
        amazonStoreInfo(state, action) {
            return {
                ...state,
                state: action.payload.state,
                region: action.payload.region,
            }
        },
        advertisingStoreInfo(state, action) {
            return {
                ...state,
                storeName: action.payload.storeName,
                spApiRegion: action.payload.region,
            }
        },
    },
    extraReducers: {
        [GetAdvertisingAPIToken.fulfilled]: (state, action) => ({
            ...state,
            storeId: action.payload.storeId,
            success: action.payload.success,
            loading: false,
            storeSuccess: true,
        }),
        [GetAdvertisingAPIToken.rejected]: (state, action) => ({
            ...state,
            loading: false,
            success: false,
            err: action.payload.err,
            storeSuccess: false,
        }),
        [GetAdvertisingAPIToken.pending]: (state, action) => ({
            ...state,
            loading: true,
            storeSuccess: false,
        }),
        [CreateStore.fulfilled]: (state, action) => ({
            ...state,
            storeId: action.payload.data,
            loading: false,
            storeSuccess: true,
        }),
        [CreateStore.rejected]: (state, action) => ({
            ...state,
            loading: false,
            storeId: '',
            storeSuccess: false,
            err: action.payload.err,
        }),
        [CreateStore.pending]: (state, action) => ({
            ...state,
            storeSuccess: false,
            storeId: '',
            loading: true,
        }),
        [GetStores.pending]: (state, action) => ({
            ...state,
            loading: true,
            getStoreFlag: false,
        }),
        [GetStores.fulfilled]: (state, action) => ({
            ...state,
            loading: false,
            success: true,
            total: action.payload.total,
            stores: action.payload.stores,
            getStoreFlag: true,
        }),
        [GetStores.rejected]: (state, action) => ({
            ...state,
            loading: false,
            err: action.payload.err,
            getStoreFlag: false,
        }),
        [EditStoreData.pending]: (state, action) => ({
            ...state,
            loading: true,
            editSuccess: false,
            marketplaceEditSuccess: false,
        }),
        [EditStoreData.fulfilled]: (state, action) => ({
            ...state,
            loading: false,
            editSuccess: true,
            message: action.payload.message,
            total: action.payload.totalStores,
            marketplaceEditSuccess: true,
        }),
        [EditStoreData.rejected]: (state, action) => ({
            ...state,
            loading: false,
            editSuccess: false,
            err: action.payload.err,
        }),
        [GetActiveProfiles.pending]: (state, action) => ({
            ...state,
            loading: true,
            success: false,
            activeMarketplacesSuccess: false,
        }),
        [GetActiveProfiles.fulfilled]: (state, action) => ({
            ...state,
            loading: false,
            success: true,
            activeMarketplaces: action.payload.marketplaces,
            activeMarketplacesSuccess: true,
        }),
        [GetActiveProfiles.rejected]: (state, action) => ({
            ...state,
            loading: false,
            success: false,
            activeMarketplacesSuccess: false,
            err: action.payload.err,
        }),
        [DeleteStore.pending]: (state, action) => ({
            ...state,
            loading: true,
            success: false,
            deleteSuccess: false,
        }),
        [DeleteStore.fulfilled]: (state, action) => ({
            ...state,
            loading: false,
            message: action.payload.message,
            deleteSuccess: true,
        }),
        [DeleteStore.rejected]: (state, action) => ({
            ...state,
            loading: false,
            success: false,
            deleteSuccess: false,
            err: action.payload.err,
        }),
    },
})

const { reducer, actions } = stores

export const {
    ClearAll,
    setField,
    SetState,
    advertisingStoreInfo,
    amazonStoreInfo,
    setMarketplaceEdit,
} = actions

export default reducer
