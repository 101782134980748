import { debounce } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Switch,
  Grid,
  Button,
  Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AgGridTable from '../../components/ag-grid';
import { getMarketplaceName } from '../../utils';

//components
import SearchBar from '../../components/search';
import Select from '../../components/select';

// store
import { productListing } from '../../redux/slices/product-slice';

const styles = {
  style: {
    position: 'absolute',
    paddingLeft: '20px',
    paddingRight: '20px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 4px 8px rgba(54, 50, 235, 0.23)',
    width: '40%',
    bgcolor: 'background.paper'
  },
  button: {
    float: 'right'
  }
};

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const dropDownData = [
  {
    value: 15,
    label: 15
  },
  {
    value: 25,
    label: 25
  },
  {
    value: 50,
    label: 50
  },
  {
    value: 100,
    label: 100
  }
];

const marketPlaces = [
  {label: 'All', value: 'All'},
  { label: 'Amazon.com', value: 'ATVPDKIKX0DER' },
  // { label: 'Amazon.ca', value: 'A2EUQ1WTGCTBG2' },
  { label: 'Amazon.com.mx', value: 'A1AM78C64UM0Y8' },
  // { label: 'Amazon.com.br', value: 'A2Q3Y263D00KWC' },
  // { label: 'Amazon.com.uk', value: 'A1F83G8C2ARO7P' },
  // { label: 'Amazon.de', value: 'A1PA6795UKMFR9' },
  // { label: 'Amazon.fr', value: 'A13V1IB3VIYZZH' },
  // { label: 'Amazon.it', value: 'APJ6JRA9NG5V4' },
  // { label: 'Amazon.es', value: 'A1RKKUPIHCS9HS' },
  // { label: 'Amazon.nl', value: 'A1805IZSGTT6HS' },
  // { label: 'Amazon.se', value: 'A2NODRKZP88ZB9' },
  // { label: 'Amazon.pl', value: 'A1C3SOZRARQ6R3' },
  // { label: 'Amazon.tr', value: 'A33AVAJ2PDY3EV' },
  // { label: 'Amazon.ae', value: 'A2VIGQ35RCS4UG' },
  // { label: 'Amazon.in', value: 'A21TJRUUN4KGV' },
  // { label: 'Amazon.sg', value: 'A19VAU5U5O7RUS' },
  // { label: 'Amazon.au', value: 'A39IBJ37TRP1C6' },
  // { label: 'Amazon.jp', value: 'A1VC38T7YXB528' }
]

const productStatus = [
  {label: 'All', value: 'All'},
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' }
]

const currencySymbol = {
  ATVPDKIKX0DER: 'USD',
  A2EUQ1WTGCTBG2: 'CSD',
  A1AM78C64UM0Y8: 'MXD'
}
const Users = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState('All');
  const [selectedFulfilement, setSelectedFulfilment] = useState('All')
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(15);
  const { success, errorMessage, isLoading, products, total } = useSelector((state) => state.product) || {};
  const defaultFilterParams = { readOnly: true };

  const [filters, setFilters] = useState({
    searchKeyword: '',
    status: '',
    marketplaceId: ''
  });
  const [seacrh, setSearch] = useState('');

  const columnRenderer = (props) => {
    const { data } = props;
    const { imageUrl, title } = data || {};
    return (<div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <img src={imageUrl} height='50' width={50} />
        </Grid>
        <Grid item xs={8}>
          <Tooltip title={title || 'N/A'}>
            <div>
              {title ? `${title.slice(0, 15)}...` : 'N/A'}
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </div>);
  };

  const marketPlaceRendered = (props) => {
    const { data } = props;
    const { marketplaceId } = data || {};
    return (
      <>{getMarketplaceName(marketplaceId)}</>
    )
  }

  const [columnDefs] = useState([
    {
      field: 'product',
      filter: true,
      resizable: true,
      filterParams: defaultFilterParams,
      cellRenderer: columnRenderer
    },
    {
      headerName: 'Seller Sku',
      field: 'sellerSku',
      filter: true,
      resizable: true,
      filterParams: defaultFilterParams
    },
    {
      field: 'asin',
      filter: true,
      resizable: true,
      filterParams: defaultFilterParams
    },
    {
      field: 'marketplace',
      filter: true,
      resizable: true,
      filterParams: defaultFilterParams,
      cellRenderer: marketPlaceRendered
    },
    {
      field: 'status',
      filter: true,
      resizable: true,
      filterParams: defaultFilterParams
    },
    {
      field: 'fulfilmentType',
      filter: true,
      resizable: true,
      filterParams: defaultFilterParams,
    },
    {
      field: 'listPrice',
      filter: true,
      resizable: true,
      filterParams: defaultFilterParams,
      cellRenderer: ({ data: { listPrice = 0, marketplaceId}}) => {
        return listPrice ? `${currencySymbol[marketplaceId]}${listPrice.toFixed(2)}` : 'N/A'
      }
    },
    {
      field: 'referralFee',
      filter: true,
      resizable: true,
      filterParams: defaultFilterParams,
      cellRenderer: ({ data: { referralFee = 0, marketplaceId}}) => {
        return referralFee ? `${currencySymbol[marketplaceId]}${referralFee.toFixed(2)}` : 'N/A'
      }
    },
    {
      field: 'fulfillmentFee',
      filter: true,
      resizable: true,
      filterParams: defaultFilterParams,
      cellRenderer: ({ data: { fulfillmentFee = 0, marketplaceId}}) => {
        return fulfillmentFee ? `${currencySymbol[marketplaceId]}${fulfillmentFee.toFixed(2)}` : 'N/A'
      }
    },
    // {
    //     field: 'available',
    //     filter: true,
    //     resizable: true,
    //     filterParams: defaultFilterParams,
    // },
  ]);

  const pageChange = (page, limit) => {
    setPageLimit(limit);
    setPageNumber(page);
  };

  useEffect(() => {
    dispatch(productListing({
      skip: (pageNumber - 1) * pageLimit,
      limit: pageLimit,
      filters
    }))
  }, [dispatch, pageLimit, pageNumber, filters])

  const onSearch = debounce((event) => {
    console.log('event', event.target)
    const keyword = event.target.value
    setPageLimit(15);
    setPageNumber(1);
    setFilters({
      ...filters,
      searchKeyword: keyword
    })
  }, 500);

  const handleMarketPlaceChange = (event) => {
    const value = event.target.value;
    setPageLimit(15);
    setPageNumber(1);
    setFilters({
      ...filters,
      marketplaceId: value
    });
    setSelectedMarketplace(value);
  }

  const handleStatusChange = (event) => {
    const value = event.target.value;
    setPageLimit(15);
    setPageNumber(1);
    setFilters({
      ...filters,
      status: value
    });
    setSelectedFulfilment(value);
  }

  return (
    <div>
      <Box mt={2} mb={2} pl={2}>
        <Grid container spacing={2}>
            <Grid xs={12} md={6} lg={6}>
              <SearchBar
                placeholder="Name/Status"
                onChange={onSearch}
              />
            </Grid>
            <Grid display="flex" justifyContent="flex-end" xs={12} md={6} lg={6}>
              <Grid container >
                <Grid xs={6}>
                  <Select data={marketPlaces}
                    sx={{ width: 185 }}
                    handleChange={handleMarketPlaceChange}
                    value={selectedMarketplace}
                  />
                </Grid>
                <Grid xs={6}>
                  <Select data={productStatus}
                    handleChange={handleStatusChange}
                    value={selectedFulfilement}
                    sx={{ width: 185 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Box>
      <AgGridTable
        columns={columnDefs}
        rows={products}
        dropDownData={dropDownData}
        totalRows={total}
        pageNumber={pageNumber}
        pageLimit={pageLimit}
        onChange={pageChange}
      />
    </div>
  );
};

export default Users;
