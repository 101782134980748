import moment from 'moment';
import jwt from 'jsonwebtoken';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { useParams, useLocation } from 'react-router';

import LabelInput from '../../components/input/label-input';
import Button from '../../components/button';
import AuthWrapper from './style';

import { ResetPassword, SetState } from '../../redux/slices/auth-slice';

const style = {
  textSuccess: {
    color: "#0B6623",
  },
  textDanger: {
    color: "#ff0000",
  }
};

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { err, message, token } = useSelector((state) => state.auth);

  const queryParams = new URLSearchParams(useLocation().search);
  const queryToken = queryParams.get('token');
  const decodedToken = jwt.decode(queryToken, { complete: true });
  const { exp } = decodedToken.payload;

  if(moment.unix(exp).isBefore(moment())) {
    history.push('/auth/sign-in');
  }

  if (!queryToken) {
    history.push('/auth/sign-in');
  }

  const passwordValidation = (password) => {
    const regEx = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})');
    if (!regEx.test(password)) {
      return false;
    }
    return true;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      dispatch(SetState({ field: 'err', value: 'Fill the required fields' }));
    } else if (password !== confirmPassword) {
      dispatch(SetState({ field: 'err', value: 'Password and Confirm Password should be same.' }));
    } else if (!passwordValidation(password)) {
      dispatch(SetState({ field: 'err', value: 'Password must contain 1 upper case letter, 1 lowercase letter, and minimum 6 characters long.' }));
    } else {
      dispatch(ResetPassword({ password, userId }));
      dispatch(SetState({ field: 'err', value: '' }));
    }
  };

  useEffect(() => {
    dispatch(SetState({ field: 'err', value: '' }));
    dispatch(SetState({ field: 'message', value: '' }));
  }, []);

  return (
    <Grid container
      direction="row"
      justifyContent="center"
      alignItems="center"
      height="100vh">
      <Grid item lg={4} md={5} sm={8} xs={12} >
        <AuthWrapper>
          <Box textAlign="center" >
            <h1>Reset Password</h1>
          </Box>
          <form
            noValidate
            autoComplete="off"
            onSubmit={(e) => handleFormSubmit(e)}
          >
            <LabelInput label="Password" type="password" onChange={(e) => setPassword(e.target.value)} />
            <LabelInput label="Confirm Password" type="password" onChange={(e) => setConfirmPassword(e.target.value)} />
            {!err ? <small style={style.textSuccess}>{message}</small> : <small style={style.textDanger}>{err}</small> }
            <Button 
              text="Reset Password"
              className="contained w-100"
              type='submit'
            />
          </form>
        </AuthWrapper>
      </Grid>
    </Grid>
  )
}

export default Index;