import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

const PublicRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (token) {
      history.push('/dashboard');
    }
  }, [token]);

  return (
    <div>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </div>
  );
}

export default PublicRoute;
