import React from 'react';
import { Stack, Box, Pagination } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';

import Loading from '../loading';
import Select from '../select';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const AgGridTable = (props) => {
  const { 
    rows, columns, dropDownData, totalRows,
    pageNumber, pageLimit: limit, loading = true, 
    className, onChange 
  } = props;

  const changePageNumber = (e, value) => {
    onChange(value, limit);
  }

  const handleLimitChange = (e) => {
    onChange(1, e.target.value);
  }


  return (
    <div className="ag-theme-alpine" style={{ height: '80vh', width: '100%' }}>
      <AgGridReact
        rowData={rows}
        columnDefs={columns}
        enableCellTextSelection={true}
        >
      </AgGridReact>
      <Stack direction="row" justifyContent="space-between" sx={{ mx: 0, my: 0, border: 1, borderColor: '#E3E6EF', padding: 1 }} alignItems="center" className="pagination">
        <Box display="flex" columnGap={5}>
          <label>Total:<span className="ml-1"><b>{totalRows}</b></span></label>
        </Box>
        <Box display="flex" columnGap={2} alignItems="center">
          <Box display="flex" alignItems="center" columnGap={2}>
            <label>Showing</label>
            <Select data={dropDownData} sx={{ width: 80 }} handleChange={handleLimitChange} value={limit} />
          </Box>
          <Pagination count={Math.ceil(totalRows / limit)} page={pageNumber} color="primary" onChange={changePageNumber} />
        </Box>
      </Stack>
    </div>
  );
};

export default AgGridTable;
