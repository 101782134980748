import Styled from 'styled-components';

const TableWrapper = Styled.div`
.MuiTableContainer-root{
  &.bottom-wrapper{
    height: calc(100vh - 303px);
    overflow: auto;
      position: relative;
  }
  &.dashboard-collpase{
    height: calc(100vh - 174px);
    position: relative;
    overflow: auto;
    .border-bottom-none{
   tr{
     table{
       tr:last-child{
         td{
          border-bottom:0
         }
       }
     }
   }
    }
  }
  &.assign-cost{
     height: calc(100vh - 210px);
    overflow: auto;
          position: relative;
  }
  &.cost-price-table{
    height: calc(100vh - 208px);
    overflow: auto;
          position: relative;
  }
  &.table-scroll{
       height: calc(100vh - 600px);
    overflow: auto;
          position: relative;
  }
  &.multiple-collpase{
    height: calc(100vh - 243px);
    overflow: auto;
    position: relative;
    table{
      thead{
        tr{
          td{
            white-space:nowrap;
          }
        }
      }
      tbody{
        tr{
          td{
                        white-space:nowrap;
          }
        }
      }
    }
  }
    table{
      &.collapsible_table{
          tbody{
            tr{
              td{
                .MuiCollapse-root{
                    table{
                       background-color: ${({ theme }) => theme['collpase-table-background']}40;
                      thead{
                          tr{
                            th{
                                background-color: ${({ theme }) => theme['collpase-table-background']}40;
                            }
                          }
                      }
                    }
                }
              }
            }
          }
      }
      thead{
          tr{
            th{
               background-color: ${({ theme }) => theme['table-haeder']};
               font-size: ${({ theme }) => theme['base-font-size']};
                color: ${({ theme }) => theme['chicago-color']};
                font-family: ${({ theme }) => theme['font-family']};
                     white-space:nowrap;
            }
          }
      }
      tbody{
          &.modal-table{
            tr{
              .MuiFormControl-root{
                  .MuiOutlinedInput-root{
                       font-size: ${({ theme }) => theme['base-font-size']};
                      font-family: ${({ theme }) => theme['font-family']};
                      color: ${({ theme }) => theme['expand-button']};
                      input{
                      padding-left:0;
                      padding-right:0;
                      }
                      fieldset{
                      border-color:transparent;
                    }
              }
            }
            }
          }
            tr{
              th{
                color: ${({ theme }) => theme['expand-button']};
                font-size: ${({ theme }) => theme['base-font-size']};
                font-family: ${({ theme }) => theme['font-family']};
              }
              td{
                  color: ${({ theme }) => theme['expand-button']};
                  font-size: ${({ theme }) => theme['base-font-size']};
                  font-family: ${({ theme }) => theme['font-family']};
                    &.active{
                        background-color: ${({ theme }) => theme['primary-color']};
                        color: ${({ theme }) => theme['white-color']};
                          button{
                            svg{
                              color: ${({ theme }) => theme['white-color']};
                            }
                          }
                    }
                   .status{
                            padding:7px 15px;
                            border-radius:50px;
                              &.sucsses{
                                 background-color:${({ theme }) => theme['success-color']};
                                 color:${({ theme }) => theme['shamrock-color']};
                                 }
                                 &.error{
                                  background-color:${({ theme }) => theme['danger-color']};
                                  color:${({ theme }) => theme['white-color']};
                                  }
                              }
                              .expand-button{
                                color: ${({ theme }) => theme['primary-color']};
                                font-size: ${({ theme }) => theme['base-font-size']};
                                font-family: ${({ theme }) => theme['font-family']};
                                padding:0;
                                .MuiButton-startIcon{
                                  margin-right:0
                                }
                                b{
                                  font-size:12px;
                                }
                              }
                                .collpase-table {
                                    table{
                                      background-color:${({ theme }) => theme['tropical-Blue']};
                                      thead{
                                          tr{
                                            th{
                                                background-color:${({ theme }) => theme['tropical-Blue']};
                                            }
                                          }
                                      }
                                      tbody{
                                        tr{
                                          td{
                                            .status{
                                              padding:7px 15px;
                                              border-radius:50px;
                                              &.sucsses{
                                                    background-color:${({ theme }) => theme['success-color']};
                                                      color:${({ theme }) => theme['shamrock-color']};
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                  }
                  &.collapse-cell{
                      a{
                          color: ${({ theme }) => theme['primary-color']};
                          font-size: ${({ theme }) => theme['base-font-size']};
                          font-family: ${({ theme }) => theme['font-family']};
                          margin: 0 10px;
                          text-decoration:none;
                          &:hover{
                            cursor:pointer
                          }
                      }
                         .MuiButtonBase-root {
                          color: ${({ theme }) => theme['primary-color']};
                          font-size: ${({ theme }) => theme['base-font-size']};
                          font-family: ${({ theme }) => theme['font-family']};
                          padding:0;
                          margin-bottom:3px
                          }
                  }
                  .cost-input{
                    margin: 0;
                    .MuiOutlinedInput-root{
                    height:30px;
                    font-size: ${({ theme }) => theme['base-font-size']};
                    color: ${({ theme }) => theme['expand-button']};
                    }
                  }
                  .table-select{
                    margin-bottom:6px;
                      .MuiOutlinedInput-root{
                        height:30px
                        margin-bottom:0 ;
                      }
                  }
              }
            }
      }
          .q36rmd{
                    span{
                      font-size: ${({ theme }) => theme['base-font-size']};
                      color: ${({ theme }) => theme['link-color']};
                      font-family: ${({ theme }) => theme['font-family']};
                      font-weight:${({ theme }) => theme['normal-weight']};
                    }
                    label{
                         font-size: ${({ theme }) => theme['base-font-size']};
                          color: ${({ theme }) => theme['gray-color']};
                          font-family: ${({ theme }) => theme['font-family']};
                    }
                    b{
                          font-size: ${({ theme }) => theme['base-font-size']};
                          color: ${({ theme }) => theme['tundora-color']};
                          font-family: ${({ theme }) => theme['font-family']};
                          font-weight:${({ theme }) => theme['normal-weight']};
                    }
                  }
    }
  }
  .pagination{
    label{
      font-size:12px;
      color: ${({ theme }) => theme['tundora-color']};
    }
    .MuiPagination-root{
      display: block;
        .MuiPagination-ul{
          li{
            button{
            &.Mui-selected{
            background-color: ${({ theme }) => theme['primary-color']};
          }
        }
        }
    }
    }
  }
  .root{
      display: flex;
      top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    justify-content:center;
    align-items: center;
  },
`
export default TableWrapper;
