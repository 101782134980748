import moment from 'moment';
import { debounce } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Switch,
  Grid,
  Button,
  Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AgGridTable from '../../components/ag-grid';
import { getMarketplaceName } from '../../utils';

//components
import SearchBar from '../../components/search';
import Select from '../../components/select';
import DateRange from '../../components/date-range';

// store
import { AsinWiseSale } from '../../redux/slices/sale-slice';

//utils
import { FormatAmount, FormatNumber } from '../../utils';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const dropDownData = [
  {
    value: 15,
    label: 15
  },
  {
    value: 25,
    label: 25
  },
  {
    value: 50,
    label: 50
  },
  {
    value: 100,
    label: 100
  }
];

const marketPlaces = [
  {label: 'All', value: 'All'},
  { label: 'Amazon.com', value: 'ATVPDKIKX0DER' },
  { label: 'Amazon.com.mx', value: 'A1AM78C64UM0Y8' },
]

const productStatus = [
  { label: 'Parent View', value: 'Parent' },
  { label: 'Child View', value: 'Child' }
]

const currencySymbol = {
  ATVPDKIKX0DER: 'USD',
  A2EUQ1WTGCTBG2: 'CSD',
  A1AM78C64UM0Y8: 'MXD'
}
const AsinWiseSaleComponent = () => {
  const dispatch = useDispatch();
  const start = moment('01/01/2022').calendar();
  const end = moment(new Date()).format('l');
  const [selectedMarketplace, setSelectedMarketplace] = useState('ATVPDKIKX0DER');
  const [selectedStatus, setSelectedStatus] = useState('Parent')
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(15);
  const { success, errorMessage, isLoading, sales, total } = useSelector((state) => state.sale) || {};
  const defaultFilterParams = { readOnly: true };

  const [filters, setFilters] = useState({
    searchKeyword: '',
    type: 'Parent',
    marketplaceId: 'ATVPDKIKX0DER',
    startDate: start,
    endDate: end
  });
  const [seacrh, setSearch] = useState('');

  const handleDateChange = (value) => {
    const {
      startDate,
      endDate
    } = value;
    console.log('date is ',
      moment(startDate, 'America/Los_Angeles').toISOString(),
      moment(endDate, 'America/Los_Angeles').toISOString()
    );
    setFilters({
      ...filters,
      startDate: moment(startDate).format(),
      endDate: moment(endDate).format()
    });
  }

  const columnRenderer = (props) => {
    const { data } = props;
    const { imageUrl, title } = data || {};
    return (<div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <img src={imageUrl} height='50' width={50} />
        </Grid>
        <Grid item xs={8}>
          <Tooltip title={title || 'N/A'}>
            <div>
              {title ? `${title.slice(0, 15)}...` : 'N/A'}
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </div>);
  };

  const marketPlaceRendered = (props) => {
    const { data } = props;
    const { marketplaceId } = data || {};
    return (
      <>{getMarketplaceName(marketplaceId)}</>
    )
  }

  const [columnDefs] = useState([
    {
      field: 'product',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: columnRenderer
    },
    {
      field: 'asin',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: ({data: {asin}}) => {
        return  asin;
      }
    },
    {
      field: 'marketplace',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: marketPlaceRendered
    },
    {
      field: 'unitsOrdered',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: ({ data: { unitsOrdered = 0}}) => {
        return `${FormatNumber(unitsOrdered)}`
      }
    },
    {
      field: 'OrderItems',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: ({ data: { totalOrderItems = 0}}) => {
        return `${FormatNumber(totalOrderItems)}`
      }
    },
    {
      field: 'orderedProductSales',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: ({ data: { orderedProductSales = 0}}) => {
        return `$${FormatNumber(orderedProductSales)}`
      }
    },
    {
      field: 'sessions',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams
    },
    {
      field: 'pageViews',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: ({data: {pageViews = 0}}) => {
        return `${FormatNumber(pageViews)}`
      }
    }
  ]);

  const [columnDefsParent] = useState([
    {
      field: 'product',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: columnRenderer
    },
    {
      field: 'parentAsin',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: ({data: {parentAsin}}) => {
        return parentAsin;
      }
    },
    {
      field: 'asin',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: ({data: {asin}}) => {
        return  asin;
      }
    },
    {
      field: 'marketplace',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: marketPlaceRendered
    },
    {
      field: 'unitsOrdered',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: ({ data: { unitsOrdered = 0}}) => {
        return `${FormatNumber(unitsOrdered)}`
      }
    },
    {
      field: 'OrderItems',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: ({ data: { totalOrderItems = 0}}) => {
        return `${FormatNumber(totalOrderItems)}`
      }
    },
    {
      field: 'orderedProductSales',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: ({ data: { orderedProductSales = 0}}) => {
        return `$${FormatNumber(orderedProductSales)}`
      }
    },
    {
      field: 'sessions',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams
    },
    {
      field: 'pageViews',
      filter: true,
      resizable: true,
      enableCellTextSelection:true,
      filterParams: defaultFilterParams,
      cellRenderer: ({data: {pageViews = 0}}) => {
        return `${FormatNumber(pageViews)}`
      }
    }
  ]);

  const pageChange = (page, limit) => {
    setPageLimit(limit);
    setPageNumber(page);
  };

  useEffect(() => {
    console.log('filter is', filters);
    dispatch(AsinWiseSale({
      skip: (pageNumber - 1) * pageLimit,
      limit: pageLimit,
      filters
    }))
  }, [dispatch, pageLimit, pageNumber, filters])

  const onSearch = debounce((event) => {
    console.log('event', event.target)
    const keyword = event.target.value
    setPageLimit(15);
    setPageNumber(1);
    setFilters({
      ...filters,
      searchKeyword: keyword
    })
  }, 500);

  const handleMarketPlaceChange = (event) => {
    const value = event.target.value;
    setPageLimit(15);
    setPageNumber(1);
    setFilters({
      ...filters,
      marketplaceId: value
    });
    setSelectedMarketplace(value);
  }

  const handleStatusChange = (event) => {
    const value = event.target.value;
    setPageLimit(15);
    setPageNumber(1);
    setFilters({
      ...filters,
      type: value
    });
    setSelectedStatus(value);
  }

  return (
    <div>
      <Box mt={2} mb={2} pl={2}>
        <Grid container spacing={2}>
            <Grid xs={12} md={7} lg={7}>
              <Grid container >
                  <Grid xs={6}>
                  <SearchBar
                    placeholder="Name/Status"
                    onChange={onSearch}
                  />
                  </Grid>
                  <Grid xs={6}>
                    <DateRange handleDateChange={handleDateChange} />
                  </Grid>
                </Grid>
            </Grid>
            <Grid display="flex" justifyContent="flex-end" xs={12} md={5} lg={5}>
              <Grid container >
                <Grid xs={6}>
                  <Select data={marketPlaces}
                    sx={{ width: 185 }}
                    handleChange={handleMarketPlaceChange}
                    value={selectedMarketplace}
                  />
                </Grid>
                <Grid xs={6}>
                  <Select data={productStatus}
                    handleChange={handleStatusChange}
                    value={selectedStatus}
                    sx={{ width: 185 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Box>
      <AgGridTable
        columns={selectedStatus === 'Parent' ? columnDefs: columnDefsParent}
        rows={sales}
        dropDownData={dropDownData}
        totalRows={total}
        pageNumber={pageNumber}
        pageLimit={pageLimit}
        onChange={pageChange}
      />
    </div>
  );
};

export default AsinWiseSaleComponent;
