export const getMarketplaceName = (marketplaceId) => {
    switch (marketplaceId) {
      case 'ATVPDKIKX0DER': {
        return 'Amazon.com';
      }
      case 'A2EUQ1WTGCTBG2': {
        return 'Amazon.ca';
      }
      case 'A1AM78C64UM0Y8': {
        return 'Amazon.com.mx';
      }
      case 'A2Q3Y263D00KWC': {
        return 'Amazon.com.br';
      }
      case 'A1F83G8C2ARO7P': {
        return 'Amazon.co.uk';
      }
      case 'A1PA6795UKMFR9': {
        return 'Amazon.de';
      }
      case 'A13V1IB3VIYZZH': {
        return 'Amazon.fr';
      }
      case 'APJ6JRA9NG5V4': {
        return 'Amazon.it';
      }
      case 'A1RKKUPIHCS9HS': {
        return 'Amazon.es';
      }
      case 'A1805IZSGTT6HS': {
        return 'Amazon.nl';
      }
      case 'A2NODRKZP88ZB9': {
        return 'Amazon.se';
      }
      case 'A1C3SOZRARQ6R3': {
        return 'Amazon.pl';
      }
      case 'A33AVAJ2PDY3EV': {
        return 'Amazon.tr';
      }
      case 'A2VIGQ35RCS4UG': {
        return 'Amazon.ae';
      }
      case 'A21TJRUUN4KGV': {
        return 'Amazon.in';
      }
      case 'A19VAU5U5O7RUS': {
        return 'Amazon.sg';
      }
      case 'A39IBJ37TRP1C6': {
        return 'Amazon.au';
      }
      case 'A1VC38T7YXB528': {
        return 'Amazon.jp';
      }
      default: {
        return 'None';
      }
    }
  };

export const FormatAmount = (amount) => {
let newAmount = parseFloat(parseFloat(amount || 0).toFixed(2));
newAmount = newAmount?.toLocaleString(undefined, { minimumFractionDigits: 2 });

return newAmount;
};

export const FormatNumber = number => number?.toLocaleString();