import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ResetPassword from '../pages/authentication/reset-password';
import PageNotFound from '../pages/other/page-not-found';

import { setAuthToken } from '../config/axios-configuration';

const NonAuthRoute = () => {
  const { token } = useSelector(state => state.auth);
  console.log("🚀 ~ file: non-auth-route.js ~ line 13 ~ NonAuthRoute ~ token", token)
  setAuthToken(token);

  return (
    <Switch>
      <Route path='/non-auth/reset-password/:userId' component={ResetPassword} />
      <Route path='/not-found' component={PageNotFound} />
      <Redirect from='*' to='/not-found' />
    </Switch>
  );
}

export default NonAuthRoute;
