import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const SignIn = createAsyncThunk(
  'auth/signIn',
  async (user, thunkAPI) => {
    try {
      const response = await axios.post('/auth/sign-in', user);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const SignUp = createAsyncThunk(
  'user/SignUp',
  async (user, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/sign-up', user);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const ForgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (user, thunkAPI) => {
    try {
      const response = await axios.post('/auth/forgot-password', user);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const ResetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (user, thunkAPI) => {
    try {
      const response = await axios.post('/auth/reset-password', user);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const auth = createSlice({
  name: 'auth',
  initialState: {
    name: '',
    email: '',
    message: '',
    err: '',
    userType: false,
    token: '',
    userId: ''
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    LogOut: (state) => ({
      name: '',
      email: '',
      message: '',
      err: '',
      token: '',
      userId: '',
      success: false
    })
  },
  extraReducers: {
    [SignIn.pending]: (state, action) => ({
      ...state
    }),
    [SignIn.fulfilled]: (state, action) => ({
      name: action.payload.user.name,
      email: action.payload.user.email,
      userId: action.payload.user.userId,
      token: action.payload.token,
      userType: action.payload.user.admin
    }),
    [SignIn.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error
    }),
    [SignUp.pending]: (state, action) => ({
        ...state
      }),
      [SignUp.fulfilled]: (state, action) => ({
        name: action.payload.user.name,
        email: action.payload.user.email,
        userId: action.payload.user.userId,
        token: action.payload.token,
        userType: action.payload.user.admin
      }),
      [SignUp.rejected]: (state, action) => ({
        ...state,
        err: action.payload.err?.errorMessage
      }),
    [ForgotPassword.pending]: (state, action) => ({
      ...state
    }),
    [ForgotPassword.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message
    }),
    [ForgotPassword.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err.error
    }),
    [ResetPassword.pending]: (state, action) => ({
      ...state
    }),
    [ResetPassword.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message
    }),
    [ResetPassword.rejected]: (state, action) => ({
      ...state,
      err: action.payload.error
    }),
    
  }
});

const { reducer, actions } = auth;

export const {
  SetState,
  LogOut
} = actions;

export default reducer;
