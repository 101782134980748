import Canada from '../assets/images/flags/north-america/canada.svg';
import Usa from '../assets/images/flags/north-america/usa.svg';
import Brazil from '../assets/images/flags/north-america/brazil.svg';
import Mexico from '../assets/images/flags/north-america/mexico.svg';
import Australia from '../assets/images/flags/far-east/australia.svg';
import Japan from '../assets/images/flags/far-east/japan.svg';
import singapore from '../assets/images/flags/far-east/singapore.svg';
import France from '../assets/images/flags/europe/france.svg';
import Germany from '../assets/images/flags/europe/germany.svg';
import Spain from '../assets/images/flags/europe/spain.svg';
import unitedKingdom from '../assets/images/flags/europe/united-kingdom.svg';
import netherlands from '../assets/images/flags/europe/netherlands.svg';
import Italy from '../assets/images/flags/europe/italy.svg';
import sweden from '../assets/images/flags/europe/sweden.svg';
import poland from '../assets/images/flags/europe/poland.svg';
import turkey from '../assets/images/flags/europe/turkey.svg';
import india from '../assets/images/flags/europe/india.svg';

export const LOCAL_API_URL = 'http://localhost:5400/v1';
export const PRODUCTION_API_URL = 'https://api.amzprofessional.com/v1';

export const APP_ID = 'amzn1.sp.solution.35b3eb03-d3e8-41c4-b6be-e586bc90a909';

export const CURRENCY_SYMBOLS = {
  USD: '$',
  CAD: 'C$',
  MXN: 'MX$',
  BRL: 'R$'
};

export const Regions = {
  na: [
    // Usa, Canada, Brazil, Mexico
    {
      marketPlaceId: 'ATVPDKIKX0DER',
      name: 'USA',
      image: Usa,
      domain: 'Amazon.com'
    },
    {
      marketPlaceId: 'A2EUQ1WTGCTBG2',
      name: 'Canada',
      image: Canada,
      domain: 'Amazon.ca'
    },
    {
      marketPlaceId: 'A2Q3Y263D00KWC',
      name: 'Brazil',
      image: Brazil,
      domain: 'Amazon.com.br'
    },
    {
      marketPlaceId: 'A1AM78C64UM0Y8',
      name: 'Mexico',
      image: Mexico,
      domain: 'Amazon.com.mx'
    }
  ],
  eu: [
    // Spain, unitedKingdom, France, netherlands, Germany, Italy,sweden, poland, turkey, india
    {
      marketPlaceId: 'A1RKKUPIHCS9HS',
      name: 'Spain',
      image: Spain,
      domain: 'Amazon.es'
    },
    {
      marketPlaceId: 'A1F83G8C2ARO7P',
      name: 'United Kindom',
      image: unitedKingdom,
      domain: 'Amazon.co.uk'
    },
    {
      marketPlaceId: 'A13V1IB3VIYZZH',
      name: 'France',
      image: France,
      domain: 'Amazon.fr'
    },
    {
      marketPlaceId: 'A1805IZSGTT6HS',
      name: 'Netherlands',
      image: netherlands,
      domain: 'Amazon.nl'
    },
    {
      marketPlaceId: 'A1PA6795UKMFR9',
      name: 'Netherlands',
      image: Germany,
      domain: 'Amazon.de'
    },
    {
      marketPlaceId: 'APJ6JRA9NG5V4',
      name: 'Italy',
      image: Italy,
      domain: 'Amazon.it'
    },
    {
      marketPlaceId: 'A2NODRKZP88ZB9',
      name: 'Sweden',
      image: sweden,
      domain: 'Amazon.se'
    },
    {
      marketPlaceId: 'A1C3SOZRARQ6R3',
      name: 'Poland',
      image: poland,
      domain: 'Amazon.pl'
    },
    {
      marketPlaceId: 'A33AVAJ2PDY3EV',
      name: 'Turkey',
      image: turkey,
      domain: 'Amazon.tr'
    },
    {
      marketPlaceId: 'A21TJRUUN4KGV',
      name: 'India',
      image: india,
      domain: 'Amazon.in'
    }
  ],
  fe: [
    // singapore, Australia, Japan
    {
      marketPlaceId: 'A19VAU5U5O7RUS',
      name: 'Singapore',
      image: singapore,
      domain: 'Amazon.sg'
    },
    {
      marketPlaceId: 'A39IBJ37TRP1C6',
      name: 'Australia',
      image: Australia,
      domain: 'Amazon.com.au'
    },
    {
      marketPlaceId: 'A1VC38T7YXB528',
      name: 'Japan',
      image: Japan,
      domain: 'Amazon.co.jp'
    },
    {
      marketPlaceId: 'A2VIGQ35RCS4UG',
      name: 'UAE',
      image: Japan,
      domain: 'Amazon.ae'
    }
  ],
  all: [
    {
      marketPlaceId: 'ATVPDKIKX0DER',
      name: 'USA',
      image: Usa,
      domain: 'Amazon.com'
    },
    {
      marketPlaceId: 'A2EUQ1WTGCTBG2',
      name: 'Canada',
      image: Canada,
      domain: 'Amazon.ca'
    },
    {
      marketPlaceId: 'A2Q3Y263D00KWC',
      name: 'Brazil',
      image: Brazil,
      domain: 'Amazon.com.br'
    },
    {
      marketPlaceId: 'A1AM78C64UM0Y8',
      name: 'Mexico',
      image: Mexico,
      domain: 'Amazon.com.mx'
    },
    {
      marketPlaceId: 'A1RKKUPIHCS9HS',
      name: 'Spain',
      image: Spain,
      domain: 'Amazon.es'
    },
    {
      marketPlaceId: 'A1F83G8C2ARO7P',
      name: 'United Kindom',
      image: unitedKingdom,
      domain: 'Amazon.co.uk'
    },
    {
      marketPlaceId: 'A13V1IB3VIYZZH',
      name: 'France',
      image: France,
      domain: 'Amazon.fr'
    },
    {
      marketPlaceId: 'A1805IZSGTT6HS',
      name: 'Netherlands',
      image: netherlands,
      domain: 'Amazon.nl'
    },
    {
      marketPlaceId: 'A1PA6795UKMFR9',
      name: 'Netherlands',
      image: Germany,
      domain: 'Amazon.de'
    },
    {
      marketPlaceId: 'APJ6JRA9NG5V4',
      name: 'Italy',
      image: Italy,
      domain: 'Amazon.it'
    },
    {
      marketPlaceId: 'A2NODRKZP88ZB9',
      name: 'Sweden',
      image: sweden,
      domain: 'Amazon.se'
    },
    {
      marketPlaceId: 'A1C3SOZRARQ6R3',
      name: 'Poland',
      image: poland,
      domain: 'Amazon.pl'
    },
    {
      marketPlaceId: 'A33AVAJ2PDY3EV',
      name: 'Turkey',
      image: turkey,
      domain: 'Amazon.tr'
    },
    {
      marketPlaceId: 'A21TJRUUN4KGV',
      name: 'India',
      image: india,
      domain: 'Amazon.in'
    },
    {
      marketPlaceId: 'A19VAU5U5O7RUS',
      name: 'Singapore',
      image: singapore,
      domain: 'Amazon.sg'
    },
    {
      marketPlaceId: 'A39IBJ37TRP1C6',
      name: 'Australia',
      image: Australia,
      domain: 'Amazon.com.au'
    },
    {
      marketPlaceId: 'A1VC38T7YXB528',
      name: 'Japan',
      image: Japan,
      domain: 'Amazon.co.jp'
    }
  ]
};
