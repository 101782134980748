import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, ListItemButton, Collapse, CssBaseline, List, Divider } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
// import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import BusinessIcon from '@mui/icons-material/Business';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import StoreIcon from '@mui/icons-material/Store';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LogOut } from '../redux/slices/auth-slice';

import SidebarWrapper from './style';

const drawerWidth = 240;
const side = [
  {
    title: "Dashboard",
    icon: <DashboardOutlinedIcon />,
    route: "/dashboard",
    collapse: [],
    key: 0,
  },
  // {
  //   title: "Listing",
  //   icon: <ListAltIcon />,
  //   route: "/listing",
  //   collapse: [],
  //   key: 1,
  // },
  // {
  //   title: "Business Data",
  //   icon: <BusinessIcon />,
  //   route: "/asin-wise-sale",
  //   collapse: [],
  //   key: 2,
  // },
  {
    title: "Stores",
    icon: <StoreIcon />,
    route: "/stores",
    collapse: [],
    key: 1,
  },
  {
    title: "Log out",
    icon: <LogoutIcon />,
    route: '/auth/sign-in',
    key: 3,
    collapse: [],
  }
]

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Layout = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [collape, setCollpase] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [enableDisableMenu, setEnableDisableMenu] = useState(false);
  const { userType, stores } = useSelector((state) => state.auth);

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const handleCollapse = () => {
    setCollpase(!collape);
  }

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };
  
  const style = {
    p: 2,
    pb: 0
  };

  const elsePart = (index, text) => (

    <List key={`list-${index}`} component="div" disablePadding>
      <ListItemButton sx={{ pl: 4 }} button="true" key={`list-item-${index}`}
        selected={selectedIndex === text.key || window.location.pathname === text.route}
        onClick={
          index === 1 ? handleCollapse :
            event => {
              handleListItemClick(event, index)
              history.push(text.route)
            }}>
        <ListItemIcon>
          {text.icon}
        </ListItemIcon>
        <ListItemText primary={text.title} />
      </ListItemButton>
    </List>
  );

  return (
    <SidebarWrapper>
      <Box>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <MenuIcon sx={{ color: '#175D9B' }} />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {side.map((text, index) => (
              <div key={`list-item-${index}`}>
                <ListItemButton button="true"
                  onClick={
                    index === 5 ? handleCollapse :
                      event => {
                        if (text.key === 3) {
                          dispatch(LogOut());
                        }
                        console.log('i am called')
                        handleListItemClick(event, text.key)
                        history.push(text.route)
                      }}

                  selected={selectedIndex === index || window.location.pathname === text.route}
                >
                  <ListItemIcon>
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText primary={text.title} />
                  {index === 5 ? collape ? <ExpandLess sx={{ color: '#62A6E2' }} /> : <ExpandMore sx={{ color: '#62A6E2' }} /> : ""}
                </ListItemButton>
                <Collapse in={text.key === 1 ? collape : false} timeout="auto" unmountOnExit>
                  {text?.collapse.map((text, index) => {
                    {
                      return (
                        userType === true ? text.key === 7 ? '' : elsePart(index, text) : text.key === 1 ? '' : elsePart(index, text)
                      )
                    }
                  })}
                </Collapse>
              </div>
            ))}

          </List>
        </Drawer>
        <Box component="main" sx={open === false ? { ...style, ml: 9.12 } : { ...style, ml: 30 }}>
          {children}
        </Box>
      </Box >
    </SidebarWrapper>
  );
}

export default Layout;
