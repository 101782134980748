import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import AppRoute from './routes/app-route';
import AuthRoute from './routes/auth-route';
import NonAuthRoute from './routes/non-auth-route';

import theme from './assets/themes';

import './assets/css/style.css';

const App = () => {
  return (
    <ThemeProvider theme={{ ...theme }}>
      <BrowserRouter>
        <Switch>
          <AuthRoute path='/auth' />
          <NonAuthRoute path='/non-auth' />
          <AppRoute path='/' />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
