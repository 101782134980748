import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Catalog = ({
    name,
    images,
    asin
}) => {

    return(<div style={{width: "150px"}}>
        <p style={{fontSize: "16px", color: "white", backgroundColor: "#0096fb", borderRadius: "11px", textAlign: "center"}}>{asin}</p>
        <Carousel autoPlay showThumbs={false} infiniteLoop={true}>
            {images.map((image, i) => (
                <img src={image.link} alt={""} key={i}/>
            ))}
        </Carousel>
        <p style={{fontSize: "12px", textWrap: "wrap"}}>{name.length > 20 ? `${name.substring(0,19)}...` : name}</p>
    </div>)

}

export default Catalog