import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { SelectWrapper } from './style_copy';
import PropTypes from 'prop-types';

const Select = props => {
  const { 
    sx, dropDownData, handleChange, showLabel=false, label, value='', className, placeholder
  } = props;
  return (
    <SelectWrapper>
      <form noValidate autoComplete="off">
        <TextField
          size="small"
          select
          placeholder={placeholder}
          label={showLabel? label: ''}
          value={value}
          onChange={handleChange}
          InputLabelProps={{ shrink: false }}
          margin="normal"
          variant="outlined"
          className={className}
          sx={sx}
        >
          {dropDownData?.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </form >
    </SelectWrapper>
  );
}
Select.propTypes = {
  handleChange: PropTypes.func,
  dropDownData: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  className: PropTypes.string
};
export default Select;
