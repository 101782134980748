import React, { useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { Route } from 'react-router-dom';

import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';

const PrivateRoute = ({
  component: Component,
  withLayout: Layout,
  ...rest
}) => {
  const history = useHistory();
  const { token } = useSelector((state) => state.auth);
  const renderRoute = () => (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );

  useEffect(() => {
    if (!token || token === '') {
      history.push('/auth/sign-in');
      return;
    } else if(token) {
      history.push(location.pathname);
    }
  }, [token]);

  return token ? (<Layout>{renderRoute()}</Layout>) : (
    <Backdrop open={true} style={{ zIndex: '9' }}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default PrivateRoute;
