import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

import ButtonWrapper from './style';

const Buttons = (props) => {
  const {
    text, icon, varient, onClick, className, disabled, type = 'button'
  } = props;

  return (
    <Stack direction="row" spacing={2}>
      <ButtonWrapper>
      <Button size="small" type={type} className={className} variant={varient} startIcon={icon} onClick={onClick} disabled={disabled}>
          {text}
        </Button>
      </ButtonWrapper>
    </Stack>
  );
}

Buttons.propTypes = {
  text: PropTypes.string,
  varient: PropTypes.string
};

export default Buttons;
