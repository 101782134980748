import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axiosBaseUrl } from '../../config/axios-configuration'

const axios = axiosBaseUrl()

export const AsinWiseSale = createAsyncThunk(
    'sales/asin-wise-sales',
    async (params, { rejectWithValue }) => {
        try {
            //   console.log({
            //     params
            //   });
            const response = await axios.get('/sales/asin-wise-sales', {
                params,
            })
            const responsedData = response.data
            return responsedData
        } catch (err) {
            if (err.response && err.response.data) {
                return rejectWithValue({
                    errorMessage: err.response.data.error,
                    status: err.response.status,
                })
            }
            return rejectWithValue({
                errorMessage: 'Network Error',
            })
        }
    }
)

const sale = createSlice({
    name: 'sale',
    initialState: {
        isLoading: false,
        success: false,
        errorMessage: '',
        total: 0,
        sales: [],
    },
    extraReducers: {
        [AsinWiseSale.pending]: (state, action) => ({
            ...state,
            isLoading: true,
        }),
        [AsinWiseSale.fulfilled]: (state, action) => ({
            ...state,
            sales: action.payload.sales,
            total: action.payload.total,
            isLoading: false,
            success: true,
        }),
        [AsinWiseSale.rejected]: (state, action) => ({
            ...state,
            isLoading: false,
            errorMessage: action.payload.errorMessage,
        }),
    },
})

const { reducer } = sale

export default reducer
